var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loadingIndicator, rounded: "sm", variant: "dark" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { staticClass: "vh-100", attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                {
                  attrs: {
                    "align-self": "center",
                    cols: "12",
                    sm: "9",
                    lg: "5"
                  }
                },
                [
                  !_vm.createGroupSuccess &&
                  !_vm.showResendEmailVerficationMessage
                    ? _c(
                        "b-card",
                        [
                          _c("img", {
                            staticClass: "webmag-logo-big",
                            attrs: {
                              src: require("@/assets/logo-webmag-big.png"),
                              alt: "webmag logo"
                            }
                          }),
                          _c("p", [_vm._v(" " + _vm._s(_vm.$t("setup.desc")))]),
                          _c(
                            "h4",
                            {
                              staticClass: "text-center",
                              style: {
                                visibility: _vm.previewUrl
                                  ? "visible"
                                  : "hidden"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.previewUrl) + " ")]
                          ),
                          _c(
                            "b-form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.onSubmit.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { id: "group-name-group" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "group-name-input",
                                      name: "group-name-input",
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "setup.placeholderInput"
                                      ),
                                      state: _vm.validateState("groupName"),
                                      "aria-describedby":
                                        "group-name-live-feedback"
                                    },
                                    on: { input: _vm.showAndValidateSlug },
                                    model: {
                                      value: _vm.$v.form.groupName.$model,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$v.form.groupName,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "$v.form.groupName.$model"
                                    }
                                  }),
                                  _c(
                                    "b-form-invalid-feedback",
                                    {
                                      attrs: { id: "group-name-live-feedback" }
                                    },
                                    [
                                      !_vm.$v.form.groupName.required
                                        ? _c("div", { staticClass: "error" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("setup.requireName")
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm.showDuplicateGroupError
                                        ? _c("div", { staticClass: "error" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("setup.duplicateName")
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm.showBlacklistGroupError
                                        ? _c("div", { staticClass: "error" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("setup.restrictedName")
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "p-3",
                                  attrs: {
                                    block: "",
                                    type: "submit",
                                    variant: "success",
                                    disabled: _vm.$v.form.$invalid
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("setup.createGroupButton")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.createGroupSuccess &&
                  !_vm.showResendEmailVerficationMessage
                    ? _c("create-group-success")
                    : _vm._e(),
                  _vm.verificationEmailSent
                    ? _c("verification-email-send-card")
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }