var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-card", [
    _c("img", {
      staticClass: "webmag-logo-big",
      attrs: {
        src: require("@/assets/logo-webmag-big.png"),
        alt: "webmag logo"
      }
    }),
    _c("p", [_vm._v(_vm._s(_vm.$t("verify.thankYouTitle")))]),
    _c("p", [_vm._v(_vm._s(_vm.$t("verify.thankYouMessage")))]),
    _c("p", [_c("strong", [_vm._v(_vm._s(_vm.$t("verify.team")))])])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }