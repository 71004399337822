var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-4" },
    _vm._l(_vm.themeFieldConfig, function(themeConfigSection, sectionIndex) {
      return _c(
        "b-row",
        { key: `sec${sectionIndex}`, staticClass: "mb-4" },
        [
          _c(
            "b-col",
            {
              staticClass: "sticky-title",
              attrs: { cols: "12", "offset-sm": "1", sm: "10" }
            },
            [
              _c("h3", [_vm._v(" " + _vm._s(themeConfigSection.title) + " ")]),
              _c("hr")
            ]
          ),
          _c(
            "b-col",
            _vm._l(themeConfigSection.fields, function(themeConfigItem, index) {
              return _c(
                "div",
                { key: index },
                [
                  _vm.showComponent(themeConfigItem)
                    ? _c(themeConfigItem.fieldType, {
                        tag: "component",
                        attrs: {
                          handle: themeConfigItem.handle,
                          label: themeConfigItem.title,
                          desc: themeConfigItem.desc,
                          checkboxLabel: themeConfigItem.checkboxLabel,
                          "value-from-db": _vm.getThemeValue(
                            themeConfigSection.handle,
                            themeConfigItem.handle
                          ),
                          "margin-bottom": themeConfigItem.marginBottom
                        },
                        on: {
                          "update-field": function($event) {
                            return _vm.updateSettingValue(
                              $event,
                              themeConfigItem.handle,
                              themeConfigSection.handle
                            )
                          },
                          "file-removed": function($event) {
                            return _vm.updateSettingValue(
                              $event,
                              themeConfigItem.handle,
                              themeConfigSection.handle
                            )
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }