var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "mb-4" },
    [
      _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
        _c("label", [_vm._v(_vm._s(_vm.label))])
      ]),
      _c("b-col", { attrs: { cols: "12", sm: "7" } }, [
        _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c(
              "b-form-checkbox",
              {
                attrs: { name: _vm.handle + "_activate", size: "lg" },
                on: {
                  input: function($event) {
                    return _vm.updateField($event)
                  }
                },
                model: {
                  value: _vm.activate,
                  callback: function($$v) {
                    _vm.activate = $$v
                  },
                  expression: "activate"
                }
              },
              [_vm._v(" " + _vm._s(_vm.activateLabel) + " ")]
            ),
            _c("b-form-text", {
              domProps: { innerHTML: _vm._s(_vm.activateDesc) }
            })
          ],
          1
        ),
        _vm.activate
          ? _c("div", [
              _c(
                "div",
                { staticClass: "mb-2" },
                [
                  _c("b-form-input", {
                    attrs: {
                      name: _vm.handle + "_url",
                      placeholder: _vm.urlPlaceholder,
                      debounce: "500"
                    },
                    on: { update: _vm.updateField },
                    model: {
                      value: _vm.url,
                      callback: function($$v) {
                        _vm.url = $$v
                      },
                      expression: "url"
                    }
                  }),
                  _c("b-form-text", {
                    domProps: { innerHTML: _vm._s(_vm.urlDesc) }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-2" },
                [
                  _c("b-form-input", {
                    attrs: {
                      name: _vm.handle + "_text",
                      placeholder: _vm.textPlaceholder,
                      debounce: "500"
                    },
                    on: { update: _vm.updateField },
                    model: {
                      value: _vm.text,
                      callback: function($$v) {
                        _vm.text = $$v
                      },
                      expression: "text"
                    }
                  }),
                  _vm.desc
                    ? _c("b-form-text", {
                        domProps: { innerHTML: _vm._s(_vm.desc) }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: _vm.handle + "_showOnMobile", size: "lg" },
                      on: { input: _vm.updateField },
                      model: {
                        value: _vm.showOnMobile,
                        callback: function($$v) {
                          _vm.showOnMobile = $$v
                        },
                        expression: "showOnMobile"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.showOnMobileLabel) + " ")]
                  ),
                  _c("b-form-text", {
                    domProps: { innerHTML: _vm._s(_vm.showOnMobileDesc) }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: _vm.handle + "_openInNewTab", size: "lg" },
                      on: {
                        input: function($event) {
                          return _vm.updateField($event)
                        }
                      },
                      model: {
                        value: _vm.openInNewTab,
                        callback: function($$v) {
                          _vm.openInNewTab = $$v
                        },
                        expression: "openInNewTab"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.openInNewTabLabel) + " ")]
                  ),
                  _c("b-form-text", {
                    domProps: { innerHTML: _vm._s(_vm.openInNewTabDesc) }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }