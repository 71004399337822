import gql from 'graphql-tag';

export default gql`
query getAllGroupsOfUserByUserId($userId: Int!) {
  users(where: {user_id: {_eq: $userId}}) {
    email
    home_group_id
    users_roles_teams {
      team {
        teams_items {
          project {
            group_id
          }
          folder {
            group_id
          }
        }
      }
    }
    users_roles_groups {
      group {
        slug
      }
    }
  }
}
`;
