var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.data, function(item, index) {
      return _c(
        "b-row",
        { key: "cl-" + index, staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "settings.project.projectType.themeSetting.navigation.navigation_links.title",
                    { number: index + 1 }
                  )
                )
              )
            ])
          ]),
          _c("b-col", { attrs: { cols: "12", sm: "7" } }, [
            index > 1
              ? _c(
                  "div",
                  { staticClass: "mb-2" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: { size: "lg" },
                        on: {
                          change: function($event) {
                            return _vm.updateField("checkbox", index, $event)
                          }
                        },
                        model: {
                          value: _vm.data[index].isActive,
                          callback: function($$v) {
                            _vm.$set(_vm.data[index], "isActive", $$v)
                          },
                          expression: "data[index].isActive"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "settings.project.projectType.themeSetting.navigation.navigation_links.activate"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.data[index].isActive
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: `name-${_vm.handle}-${index}`,
                          placeholder: _vm.namePlaceholder,
                          debounce: "500"
                        },
                        on: {
                          update: function($event) {
                            return _vm.updateField("input", index, $event)
                          }
                        },
                        model: {
                          value: _vm.data[index].name,
                          callback: function($$v) {
                            _vm.$set(_vm.data[index], "name", $$v)
                          },
                          expression: "data[index].name"
                        }
                      }),
                      _c("b-form-text", {
                        domProps: { innerHTML: _vm._s(_vm.nameDesc) }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: `slug-${_vm.handle}-${index}`,
                          options: _vm.articleOptions
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateField("select", index, $event)
                          }
                        },
                        model: {
                          value: _vm.data[index].slug,
                          callback: function($$v) {
                            _vm.$set(_vm.data[index], "slug", $$v)
                          },
                          expression: "data[index].slug"
                        }
                      }),
                      _c("b-form-text", {
                        domProps: { innerHTML: _vm._s(_vm.articleDesc) }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: `icon-${_vm.handle}-${index}`,
                          placeholder: _vm.iconPlaceholder,
                          debounce: "500"
                        },
                        on: {
                          update: function($event) {
                            return _vm.updateField("input", index, $event)
                          }
                        },
                        model: {
                          value: _vm.data[index].icon,
                          callback: function($$v) {
                            _vm.$set(_vm.data[index], "icon", $$v)
                          },
                          expression: "data[index].icon"
                        }
                      }),
                      _c("b-form-text", {
                        domProps: { innerHTML: _vm._s(_vm.iconDesc) }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: `svgIcon-${_vm.handle}-${index}`,
                          debounce: "500",
                          rows: "6",
                          "max-rows": "10",
                          placeholder: _vm.svgIconPlaceholder
                        },
                        on: {
                          update: function($event) {
                            return _vm.updateField("textarea", index, $event)
                          }
                        },
                        model: {
                          value: _vm.data[index].iconSvg,
                          callback: function($$v) {
                            _vm.$set(_vm.data[index], "iconSvg", $$v)
                          },
                          expression: "data[index].iconSvg"
                        }
                      }),
                      _c("b-form-text", {
                        domProps: { innerHTML: _vm._s(_vm.svgIconDesc) }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }