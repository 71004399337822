<template>
  <b-card>
    <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo"/>
    <p>{{ $t('verify.thankYouTitle') }}</p>
    <p>{{ $t('verify.thankYouMessage') }}</p>
    <p><strong>{{ $t('verify.team') }}</strong></p>
  </b-card>
</template>

<script>
export default {
  name: 'VerificationEmailSendCard',
};
</script>
