<template>
  <b-card class="text-center">
    <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo"/>
    <p><strong>{{ $t('setup.thankYou') }}</strong></p>
    <p v-html="$t('setup.thankYouMessage')"></p>
    <b-button
      block
      class="p-3"
      variant="success"
      @click="goToApp"
    >
      {{ $t('setup.startNowButton') }}
    </b-button>
  </b-card>
</template>

<script>
export default {
  name: 'CreateGroupSuccess',
  methods: {
    goToApp() {
      this.$router.push('/').catch((err) => {
        throw new Error(`Routing Error occurred! ${err}.`);
      });
    },
  },
};
</script>
