var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "text-center" },
    [
      _c("img", {
        staticClass: "webmag-logo-big",
        attrs: {
          src: require("@/assets/logo-webmag-big.png"),
          alt: "webmag logo"
        }
      }),
      _c("p", [_c("strong", [_vm._v(_vm._s(_vm.$t("setup.thankYou")))])]),
      _c("p", {
        domProps: { innerHTML: _vm._s(_vm.$t("setup.thankYouMessage")) }
      }),
      _c(
        "b-button",
        {
          staticClass: "p-3",
          attrs: { block: "", variant: "success" },
          on: { click: _vm.goToApp }
        },
        [_vm._v(" " + _vm._s(_vm.$t("setup.startNowButton")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }