import gql from 'graphql-tag';

export default gql`
mutation MyMutation($obj: SetupEditorUserInput!) {
  setupEditorUser(object: $obj) {
    group_id
    user_id
  }
}
`;
